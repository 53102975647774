import React from "react";
import { Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useDeviceType from "../../hooks/useDeviceType";
import "./AboutMe.scss";

const AboutMe = () => {
  const theme = useTheme();
  const deviceType = useDeviceType();

  const isMobile = deviceType === "mobile";
  const isTablet = deviceType === "tablet";

  return (
    <Box
      className="aboutme"
      id="about-me"
      sx={{
        mx: "auto",
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        py: "8",
        px: "4",
      }}
    >
      <Typography
        className="hero__title"
        variant="h1"
        component="h1"
        sx={{
          fontSize: isTablet ? "2rem" : "2.5rem",
          padding: isTablet ? "1rem 0" : "1rem 0 2.5rem 0",
          fontWeight: "700",
          marginTop: isMobile ? "3rem" : isTablet ? "9rem" : "13rem",
          textAlign: "center",
          color: theme.palette.text.primary,
        }}
      >
        Corey Duke
      </Typography>
      <Typography
        className="hero__subtitle"
        id="about-me"
        variant="h3"
        component="h3"
        sx={{
          fontSize: isTablet ? "1.5rem" : "1.9rem",
          fontWeight: "500",
          textAlign: "left",
          width: "85%",
          color: theme.palette.secondary.main,
        }}
      >
        Software Engineer <span>|</span> Founder <span>|</span> Sustainability
        and Resilience <span>|</span> AI Prompt Engineering
      </Typography>
    </Box>
  );
};

export default AboutMe;
