import React from "react";
import { ThemeProvider, Box } from "@mui/material";
import theme from "../../Theme.js";
import AboutMe from "../../components/AboutMe/AboutMe.js";
import Hero from "../../components/Hero/Hero.js";
import MySkills from "../../components/MySkills/MySkills.js";
import Header from "../../components/NavBar/NavBar";
import Projects from "../../components/Projects/Projects.js";
import Footer from "../../components/Footer/Footer.js";
import useDeviceType from "../../hooks/useDeviceType";
import "./Home.scss";
import HeroText from "../../components/Hero/HeroText/HeroText";

export default function Home() {
  const deviceType = useDeviceType();
  const isTabletOrDesktop = deviceType !== "mobile";

  return (
    <>
      <body>
        <ThemeProvider theme={theme}>
          <Header />
          <Box
            className="hero-about-wrapper"
            display={isTabletOrDesktop ? "flex" : "block"}
            flexDirection={isTabletOrDesktop ? "row" : "column"}
          >
            <Box
              className="hero-section"
              width={isTabletOrDesktop ? "48vw" : "auto"}
              px={isTabletOrDesktop ? 1 : 0}
            >
              <Hero />
            </Box>
            <Box
              className="about-section"
              width={isTabletOrDesktop ? "48vw" : "auto"}
              px={isTabletOrDesktop ? 2 : 0}
            >
              <AboutMe />
            </Box>
          </Box>
          <HeroText />
        </ThemeProvider>
        <MySkills />
        <Projects />
        <Footer />
      </body>
    </>
  );
}
