// write the component to display the hero section
import React from "react";
import heroImage from "../../assets/images/hero_image-desk-tab.png";
import "./Hero.scss";

const Hero = () => {
  return (
    <section id="hero" className="hero">
      <img
        className="hero__image"
        src={heroImage}
        alt="Corey in a bubble with code in the background"
      />
    </section>
  );
};

export default Hero;
