// hooks/useHideOnScroll.js
import { useState, useEffect } from "react";

const useHideOnScroll = () => {
  const [trigger, setTrigger] = useState(false);
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (initialRender) {
        setInitialRender(false);
        return;
      }

      const currentScrollPos = window.pageYOffset;
      const trigger = currentScrollPos > 50;

      setTrigger(trigger);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [initialRender]);

  return trigger;
};

export default useHideOnScroll;
