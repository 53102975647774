import React from "react";
import "./MySkills.scss";
import useDeviceType from "../../hooks/useDeviceType";
import aws from "../../assets/icons/icon-aws-hover.png";
import css from "../../assets/icons/icon-css-hover.png";
import figma from "../../assets/icons/icon-figma-hover.png";
import git from "../../assets/icons/icon-git-hover.png";
import github from "../../assets/icons/icon-github-hover.png";
import html from "../../assets/icons/icon-html-hover.png";
import java from "../../assets/icons/icon-java-hover.png";
import javascript from "../../assets/icons/icon-js-hover.png";
import mysql from "../../assets/icons/icon-mysql-hover.png";
import node from "../../assets/icons/icon-node-hover.png";
import python from "../../assets/icons/icon-python-hover.png";
import react from "../../assets/icons/icon-react-hover.png";
import scss from "../../assets/icons/icon-scss-hover.png";
import bwaws from "../../assets/icons/icon-aws.png";
import bwcss from "../../assets/icons/icon-css.png";
import bwfigma from "../../assets/icons/icon-figma.png";
import bwgit from "../../assets/icons/icon-git.png";
import bwgithub from "../../assets/icons/icon-github.png";
import bwhtml from "../../assets/icons/icon-html.png";
import bwjava from "../../assets/icons/icon-java.png";
import bwjavascript from "../../assets/icons/icon-js.png";
import bwmysql from "../../assets/icons/icon-mysql.png";
import bwnode from "../../assets/icons/icon-node.png";
import bwpython from "../../assets/icons/icon-python.png";
import bwreact from "../../assets/icons/icon-react.png";
import bwscss from "../../assets/icons/icon-scss.png";

const MySkills = () => {
  const deviceType = useDeviceType(); // Use the hook to get the current device type
  const isDesktop = deviceType === "desktop"; // Check if the current device type is desktop

  const skills = [
    {
      name: "AWS",
      icon: aws,
      bwicon: bwaws,
    },
    {
      name: "CSS",
      icon: css,
      bwicon: bwcss,
    },
    {
      name: "Figma",
      icon: figma,
      bwicon: bwfigma,
    },
    {
      name: "Git",
      icon: git,
      bwicon: bwgit,
    },
    {
      name: "GitHub",
      icon: github,
      bwicon: bwgithub,
    },
    {
      name: "HTML",
      icon: html,
      bwicon: bwhtml,
    },
    {
      name: "JAVA",
      icon: java,
      bwicon: bwjava,
    },
    {
      name: "JavaScript",
      icon: javascript,
      bwicon: bwjavascript,
    },
    {
      name: "MySQL",
      icon: mysql,
      bwicon: bwmysql,
    },
    {
      name: "Node.js",
      icon: node,
      bwicon: bwnode,
    },
    {
      name: "Python",
      icon: python,
      bwicon: bwpython,
    },
    {
      name: "React",
      icon: react,
      bwicon: bwreact,
    },
    {
      name: "SASS",
      icon: scss,
      bwicon: bwscss,
    },
  ];

  return (
    <section className="my-skills" id="my-skills">
      <h2 className="my-skills__title">My Skills</h2>
      <div className="my-skills__container">
        {skills.map((skill) => (
          <div
            className={`my-skills__skill${
              isDesktop ? " my-skills__skill--desktop" : ""
            }`}
            style={{
              "--icon": `url(${skill.icon})`,
              "--bwicon": isDesktop
                ? `url(${skill.bwicon})`
                : `url(${skill.icon})`,
            }}
          >
            <div className="my-skills__skill-icon-bg"></div>
            <p className="my-skills__skill-name">{skill.name}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default MySkills;
