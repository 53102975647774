import React from "react";
import { useTheme } from "@mui/material/styles";
import { AppBar, Toolbar, Button, Box, Typography, Slide } from "@mui/material";

const TabletNavbar = ({ scrollTo, shouldHideOnScroll }) => {
  const theme = useTheme();
  return (
    <Slide appear={false} direction="down" in={true}>
      <AppBar
        sx={{ backgroundColor: theme.palette.primary.main }}
        position="sticky"
        color="default"
      >
        <Toolbar>
          <Box display="flex" justifyContent="center" width="100%">
            <Typography
              variant="h6"
              component="div"
              onClick={() => scrollTo("about-me")}
            >
              <img
                className="logo"
                src="https://i.imgur.com/Q7vKspH.png"
                alt="Cdukedev logo"
              />
            </Typography>
          </Box>
        </Toolbar>
        <Toolbar>
          <Box display="flex" justifyContent="center" width="100%">
            <Button
              sx={{ color: theme.palette.secondary.main }}
              onClick={() => scrollTo("about-me")}
            >
              About Me
            </Button>
            <Button
              sx={{ color: theme.palette.secondary.main }}
              onClick={() => scrollTo("my-skills")}
            >
              My Skills
            </Button>
            <Button
              sx={{ color: theme.palette.secondary.main }}
              onClick={() => scrollTo("projects")}
            >
              Projects
            </Button>
            <Button
              sx={{ color: theme.palette.secondary.main }}
              onClick={() => scrollTo("contact")}
            >
              Contact
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </Slide>
  );
};

export default TabletNavbar;
