import { createTheme } from "@mui/material/styles";

const primaryColor = "#1F1E1B";
const primaryTextColor = "#F0E3CA";
const activeTextColor = "#FF8303";

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: primaryTextColor,
    },
    text: {
      primary: activeTextColor,
    },
  },
});
export default theme;
