import React from "react";
import ProjectCard from "../ProjectCard/ProjectCard";
import "./Projects.scss";

const Projects = () => {
  const projects = [
    {
      title: "BeBold",
      summary:
        "An extension to bold the firsthalf of each word on a webpage,designed to assist individuals with ADHD.",
      image: "https://i.imgur.com/1aRrmQK.jpg",
      url: "https://chrome.google.com/webstore/detail/bebold/ckgjflmajkijpaipoijlpleihipnpjma",
      githubUrl: "https://github.com/cdukedev/BeBold",
    },
    {
      title: "CodeNected",
      summary:
        "Platform to help students connect to build projects they are passionate about and needed by society.",
      image: "https://i.imgur.com/HnAQdwt.png",
      url: "https://main.d1hicr0u9dvs8h.amplifyapp.com/",
      githubUrl: "https://github.com/SparkDevWebIII/codenected",
    },
    {
      title: "Gathering Miami",
      summary:
        "Web Application to build a delivery system for food banks to help feed homebound individuals.",
      image: "https://i.imgur.com/PFe9TkG.png",
      url: "https://main.d3d9kolrqh2fvt.amplifyapp.com/",
      githubUrl: "https://github.com/cdukedev/gatheringmia",
    },
    {
      title: "DevExpert",
      summary:
        "Slack bot to help student developers find answers to their questions, and educators to learn more about their students needs.",
      image: "https://i.imgur.com/6kzEyJa.gif",
      url: "",
      githubUrl: "https://github.com/cdukedev/DevExpert",
    },
  ];

  return (
    <section className="projects" id="projects">
      <h2 className="projects__title">My Projects</h2>
      <div className="projects__container">
        {projects.map((project) => (
          <ProjectCard
            key={project.title}
            title={project.title}
            summary={project.summary}
            image={project.image}
            url={project.url}
            githubUrl={project.githubUrl}
          />
        ))}
      </div>
    </section>
  );
};

export default Projects;
