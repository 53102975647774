import React from "react";
import "./NavBar.scss";
import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Drawer,
  List,
  ListItem,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const MobileNavbar = ({
  scrollTo,
  shouldHideOnScroll,
  handleDrawerToggle,
  isDrawerOpen,
}) => {
  const theme = useTheme();

  const handleListItemClick = (scrollToLocation) => {
    scrollTo(scrollToLocation);
    setTimeout(() => {
      handleDrawerToggle();
    }, 700);
  };

  return (
    <>
      <Slide in={shouldHideOnScroll} position="fixed">
        <AppBar
          sx={{ backgroundColor: theme.palette.primary.main }}
          position="fixed"
          color="default"
        >
          <Toolbar>
            <Box display="flex" alignItems="center" flexGrow={1}>
              <Typography
                variant="h6"
                component="div"
                onClick={() => scrollTo("hero")}
              >
                <img
                  className="logo"
                  src="https://i.imgur.com/Q7vKspH.png"
                  alt="Cdukedev logo"
                />
              </Typography>
            </Box>
            <IconButton
              sx={{ color: theme.palette.secondary.main }}
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Slide>
      <Drawer
        sx={{ color: theme.palette.secondary.main }}
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
      >
        <List>
          <ListItem
            className="listItem"
            onClick={() => handleListItemClick("about-me")}
          >
            About Me
          </ListItem>
          <ListItem
            className="listItem"
            onClick={() => handleListItemClick("my-skills")}
          >
            My Skills
          </ListItem>
          <ListItem
            className="listItem"
            onClick={() => handleListItemClick("projects")}
          >
            Projects
          </ListItem>
          <ListItem
            className="listItem"
            onClick={() => handleListItemClick("contact")}
          >
            Contact
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default MobileNavbar;
