import React from "react";
import { useTheme } from "@mui/material/styles";
import { AppBar, Toolbar, Typography, Button, Box } from "@mui/material";

const DesktopNavbar = ({ scrollTo }) => {
  const theme = useTheme();
  return (
    <AppBar
      position="fixed"
      color="default"
      sx={{ backgroundColor: theme.palette.primary.main }}
    >
      <Toolbar>
        <Box display="flex" justifyContent="space-between" flexGrow={1}>
          <Typography variant="h6" component="div">
            <img src="https://i.imgur.com/Q7vKspH.png" alt="Cdukedev logo" />
          </Typography>
          <Box>
            <Button
              sx={{ color: theme.palette.secondary.main }}
              onClick={() => scrollTo("about-me")}
            >
              About Me
            </Button>
            <Button
              sx={{ color: theme.palette.secondary.main }}
              onClick={() => scrollTo("my-skills")}
            >
              My Skills
            </Button>
            <Button
              sx={{ color: theme.palette.secondary.main }}
              onClick={() => scrollTo("projects")}
            >
              Projects
            </Button>
            <Button
              sx={{ color: theme.palette.secondary.main }}
              onClick={() => scrollTo("contact")}
            >
              Contact
            </Button>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default DesktopNavbar;
