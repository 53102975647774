// import logo from "./logo.svg";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
// import Schedule from "./pages/Schedule/Schedule";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/about" element={<Schedule />} /> */}
      </Routes>
    </>
  );
}

export default App;
