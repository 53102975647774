import React from "react";
import { Typography, Box } from "@mui/material";
import theme from "../../../Theme.js";
import useDeviceType from "../../../hooks/useDeviceType";
import "./HeroText.scss";

const HeroText = () => {
  const deviceType = useDeviceType();
  const isTablet = deviceType === "tablet";

  return (
    <Box
      sx={{
        width: "100vw",
        mx: "auto",
        display: "flex",
        marginTop: "1rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        className="hero-text__body"
        variant="body1"
        component="p"
        sx={{
          fontSize: isTablet ? ".8rem" : "1rem",
          textAlign: "center",
          padding: "1rem 0",
          width: "85vw",
          color: theme.palette.secondary.main,
        }}
      >
        Hi! I'm Corey. I'm passionate about technology, sustainability, and
        helping people and communities thrive. I believe in harnessing the power
        of technology to create solutions that positively impact people's lives
        and promote environmentally conscious living. With a diverse background
        in music performance, education, management, and urban resilience, I
        bring a unique skill set to my work. I've always been drawn to
        cutting-edge technologies and have focused the last three years on my
        further education with online and community college courses, earning
        over 25 certificates from various platforms. My passion for technology
        and user-centered design was solidified during a 12-week software
        engineering boot camp, where I developed a strong foundation in
        front-end and back-end technologies.
      </Typography>
    </Box>
  );
};

export default HeroText;
