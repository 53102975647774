import React from "react";
import "./ProjectCard.scss";

const ProjectCard = ({ title, summary, image, url, githubUrl }) => {
  return (
    <div className="project-card">
      <div
        className="project-card__image"
        style={{ backgroundImage: `url(${image})` }}
      ></div>
      <div className="project-card__content">
        <h3 className="project-card__title">{title}</h3>
        <p className="project-card__summary">{summary}</p>
        <div className="project-card__links">
          {/* if url === "" then don't show the link */}
          {url === "" ? null : (
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              className="project-card__link"
            >
              Live Demo
            </a>
          )}
          <a
            href={githubUrl}
            target="_blank"
            rel="noreferrer"
            className="project-card__link"
          >
            GitHub
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
