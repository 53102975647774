import React from "react";
import "./Footer.scss";
import email from "../../assets/icons/email.png";
import linkedin from "../../assets/icons/linkedin.png";
import github from "../../assets/icons/github.png";
import resume from "../../assets/corey-duke-software-engineer1.pdf";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import Link from "@mui/material/Link";

const Footer = () => {
  const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#2d2c29",
    color: "#FF8303",
    "&:hover": {
      backgroundColor: "#21201d",
      color: "#FFFFFF",
    },
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
  }));
  return (
    <footer className="footer" id="contact">
      <img
        className="footer__logo"
        src="https://i.imgur.com/Q7vKspH.png"
        alt="Logo"
      />
      <p className="footer__text">
        If you would like to discuss a project, need guidance, or need my skills
        within your organization, feel free to contact me by email or LinkedIn.
        I look forward to hearing from you!
      </p>
  {/*
      <StyledButton component={Link} href={resume} download>
         Download My Resume
      </StyledButton>
      */}
      <h3 className="footer__contacts">Contacts</h3>
      <div className="footer__icons">
        <a href="#" className="footer__icon-link">
          <img src={email} alt="" />
        </a>
        <a href="#" className="footer__icon-link">
          <img src={linkedin} alt="" />
        </a>
        <a href="#" className="footer__icon-link">
          <img src={github} alt="" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
