import React, { useState } from "react";
import DesktopNavbar from "./DesktopNavbar";
import TabletNavbar from "./TabletNavbar";
import MobileNavbar from "./MobileNavbar";
import useDeviceType from "../../hooks/useDeviceType";
import useHideOnScroll from "../../hooks/useHideOnScroll";

const Navbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const deviceType = useDeviceType();
  const trigger = useHideOnScroll();

  const scrollTo = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  switch (deviceType) {
    case "desktop":
      return <DesktopNavbar scrollTo={scrollTo} />;
    case "tablet":
      return <TabletNavbar scrollTo={scrollTo} />;
    case "mobile":
      return (
        <MobileNavbar
          scrollTo={scrollTo}
          shouldHideOnScroll={true}
          handleDrawerToggle={handleDrawerToggle}
          isDrawerOpen={isDrawerOpen}
        />
      );
    default:
      return null;
  }
};

export default Navbar;
